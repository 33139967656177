@import './edit-block/index.scss';
@import './common/index.scss';
@import './course-import/index.scss';
@import './list-libraries/index.scss';
@import 'src/library-authoring/author-library/index.scss';
@import './library-access/index.scss';
@import './create-library/index.scss';

// todo: maybe add another file for these?
.container-fluid {
    max-width: 1280px;
}

html {
    height: 100%;
}

body {
    height: 100%;
    background-color: $light-200;
}

// todo: probably want to move this to the header component
// but for now i'm just making changes here so the logos in
// the header and the footer line up at every viewport width
.site-header-desktop {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.library-authoring__root {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.library-authoring__main-content {
    margin-top: 30px;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    flex: 1 0 auto;
    display: block;
}

.tags-count-manage-button {
    border: 0;

    &:hover {
        color: white;
        background-color: $primary;
    }
}
