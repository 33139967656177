.library-authoring-block-card-header {
    .pgn__card-header-actions {
        margin-top: 0.3rem;
        margin-bottom: 0.3rem;
    }

    .pgn__card-header-content {
        margin-top: 20px !important;
    }
}

.library-authoring-sidebar {
    font-size: 90%;
    h3 {
      font-size: 100%;
      font-weight: bold;
    }
}

.library-blocks-pagination {
    ul.pagination {
        justify-content: center;
    }
}

.minimal-pagination {
    ul.pagination {
        margin: 0;
    }
}

.library-authoring-block-add-new {
    h2 {
        font-size: 120%;
    }
}

// TODO: We might need to move these styles to another file
.drawer-cover {
  z-index: 1000;

  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.drawer {
  z-index: 10000;

  position: fixed;
  top: 0;
  right: 0;
  width: 33.33vw;
  height: 100vh;
  background-color: $light-200;
}

// This is to prevent the darkened part of the page from being scrollable
body.drawer-open {
  overflow: hidden;
}
